.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #9b7c39;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.navbar-brand-text{
  font-family: cursive;
  font-Size: 2rem;
  color: #fa94c7d2;
}
#tasks-container {
  height: 450px;
  overflow: scroll;
  background-color: #292121d2;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(100, 99, 99, 0.966);
  padding: 0px;
  margin-bottom: 15px;
}
.task-list {
  padding: 10px;

}

#tasks-container::-webkit-scrollbar {
  width: 0.3em;
  height: 0.0em;
}

#tasks-container::-webkit-scrollbar-thumb {
  background-color: rgb(126, 126, 126);
  border-radius: 10px;
}
.task-item {
  position: relative;
}

.task-delete-btn {
  margin-left: 5px;
}
.task-btns{
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
}
.task-update-btn{
  margin: 1px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
